import { getArtistName, useAutoQuery } from "../utils"
import { getYears } from "../ApiClient"
import { Card, CardContent, CardHeader } from "./ui/card"
import { Link } from "react-router-dom"


export function Years() {
    const {data: groups, isPending} = useAutoQuery(getYears)

    if (isPending) {
        return
    }

    return (
        <div className="flex flex-row gap-4 overflow-x-scroll w-full">
            {
                groups.map(group => <Year group={group}/>)
            }
        </div>
    )
}

function Year({group}) {
    return (
        <Link to={`/year/${group.year}`}>
            <Card>
                <CardHeader className="font-semibold">
                    {group.year}
                </CardHeader>
                <CardContent className="flex flex-row gap-2 text-nowrap">
                    {group.topArtists.map(artistGroup => <Artist group={artistGroup}/>)}
                </CardContent>
            </Card>
        </Link>
    )
}

function Artist({group}) {
    let artist = group.artist
    let name = getArtistName(artist)
    return (
        <div className="flex flex-col justify-center w-[5rem] overflow-hidden">
            <img src={artist.images[0]?.url} alt={name} className="rounded-lg w-full object-cover aspect-square"/>
            <span className="w-full text-sm">{name}</span>
        </div>
    )
}