import { create } from "zustand"
import { getCurrentUser } from "../ApiClient"
import { tokenStore } from "./ApiTokenStore"
import * as Sentry from "@sentry/browser"

export const currentUserStore = create((set) => ({
    currentUser: null,
    updateUser: async () => {
        var token = tokenStore.getState().token
        if (token) {
            const currentUser = await getCurrentUser()
            Sentry.setUser({
                id: currentUser.id,
                username: currentUser.username,
            })
            set({currentUser: currentUser})
        } else {
            set({currentUser: null})
        }
    },
}))

tokenStore.subscribe(_ => currentUserStore.getState().updateUser())

currentUserStore.getState().updateUser()
