import { create } from "zustand"

export const tokenStore = create((set) => {
    let token = localStorage.getItem("token")
    return {
        token: token,
        setToken: (token) => {
            localStorage.setItem("token", token)
            set({token: token})
        },
        clearToken: () => {
            localStorage.removeItem("token")
            set({token: null})
        },
        hasToken: () => token !== null,
    }
})
