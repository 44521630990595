import {
    acceptFriendRequest,
    cancelFriendRequest,
    getUserByUsername,
    rejectFriendRequest,
    sendFriendRequest,
    unfriend,
} from "../ApiClient"
import { invalidateAutoQuery, useAutoQuery } from "../utils"
import { useParams } from "react-router-dom"
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar"
import React from "react"
import { Button } from "../components/ui/button"
import { UserIcon, UserMinusIcon, UserPlusIcon, UserXIcon } from "lucide-react"
import { Card, CardContent } from "../components/ui/card"
import { useQueryClient } from "@tanstack/react-query"
import { Badge } from "../components/ui/badge"
import { currentUserStore } from "../stores/CurrentUserStore"

function Header({user}) {
    const currentUser = currentUserStore(state => state.currentUser)

    return (
        <Card>
            <CardContent className="flex flex-row justify-between gap-4 flex-wrap">
                <div className="flex flex-row gap-4 items-center">
                    <Avatar className="h-[250px] w-[250px]">
                        <AvatarImage src={user.images?.[0].url}/>
                        <AvatarFallback>{user.displayName}</AvatarFallback>
                    </Avatar>
                    <div className="flex flex-col">
                        <div className="flex flex-row gap-2 items-center">
                            <h1 className="text-2xl font-bold">{user.displayName}</h1>
                            {user.friend ? <FriendBadge/> : null}
                        </div>
                    </div>
                </div>
                {user.id !== currentUser.id ? <FriendButton user={user}/> : null}
            </CardContent>
        </Card>
    )
}

function FriendBadge() {
    return (
        <Badge variant="secondary">
            <UserIcon className="h-4 w-4"/>
            Friend
        </Badge>
    )
}

function FriendButton({user}) {
    const queryClient = useQueryClient()
    const invalidate = () => invalidateAutoQuery(queryClient, getUserByUsername, user.id)

    if (user.friend) {
        const onClick = async () => {
            await unfriend(user.id)
            invalidate()
        }
        return (
            <Button variant="outline" onClick={onClick}>
                <UserXIcon/>
                Unfriend
            </Button>
        )
    } else if (user.incomingFriendRequest) {
        const accept = async () => {
            await acceptFriendRequest(user.id)
            invalidate()
        }
        const reject = async () => {
            await rejectFriendRequest(user.id)
            invalidate()
        }
        return (
            <div className="flex flex-col gap-2">
                <Button onClick={accept}>
                    <UserPlusIcon/>
                    Accept Friend Request
                </Button>
                <Button onClick={reject}>
                    <UserPlusIcon/>
                    Reject Friend Request
                </Button>
            </div>
        )
    } else if (user.outgoingFriendRequest) {
        const cancel = async () => {
            await cancelFriendRequest(user.id)
            invalidate()
        }
        return (
            <Button onClick={cancel}>
                <UserMinusIcon/>
                Cancel Friend Request
            </Button>
        )
    } else {
        const onClick = async () => {
            await sendFriendRequest(user.id)
            invalidate()
        }
        return (
            <Button onClick={onClick}>
                <UserPlusIcon/>
                Send Friend Request
            </Button>
        )
    }
}

export function UserPage() {
    const {username} = useParams()

    let {data: user, isPending} = useAutoQuery(getUserByUsername, username)
    if (isPending) {
        return
    }

    return (
        <div className="w-full">
            <Header user={user}/>
        </div>
    )
}