import { useParams } from "react-router-dom"
import { YearDashboardBody } from "../components/dashboard/YearDashboardBody"

export function YearPage() {
    const {year} = useParams()

    return (
        <div className="flex flex-col gap-4">
            <h2 className="text-5xl">Your {year}</h2>
            <YearDashboardBody year={Number(year)}/>
        </div>
    )

}