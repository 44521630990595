import React from "react"
import { DateTime } from "luxon"
import { TopArtists } from "../TopArtists"
import { TopReleaseGroups } from "../TopReleaseGroups"
import { TopRecordings } from "../TopRecordings"
import { TopTags } from "../TopTags"
import { Graph } from "../Graph"
import { getArtistName, useAutoQuery } from "../../utils"
import { getArtistsGraph } from "../../ApiClient"

export function YearDashboardBody({year}) {
    const start = DateTime.local(year).startOf("year")
    const end = DateTime.local(year).endOf("year")

    return (
        <div className="flex flex-col gap-4 w-full">
            <ArtistGraph start={start} end={end}/>
            <div className="w-full grid gap-4 sm:grid-cols-1 md:grid-cols-2">
                <TopArtists start={start} end={end}/>
                <TopReleaseGroups start={start} end={end}/>
                <TopRecordings start={start} end={end}/>
                <TopTags start={start} end={end}/>
            </div>
        </div>
    );
}

function ArtistGraph({start, end}) {
    const {data: graph, isPending} = useAutoQuery(getArtistsGraph, start, end)

    if (isPending) {
        return
    }

    return (
        <Graph
            graph={graph}
            nameFormatter={getArtistName}
        />
    )
}